@font-face {
  font-family: "iconfont"; /* Project id 3922893 */
  src: url('iconfont.woff2?t=1677589678304') format('woff2'),
       url('iconfont.woff?t=1677589678304') format('woff'),
       url('iconfont.ttf?t=1677589678304') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
}

.icon-calc:before {
  content: "\e648";
}

.icon-people:before {
  content: "\e649";
}

.icon-qb:before {
  content: "\e64e";
}

.icon-wallet:before {
  content: "\e64f";
}

.icon-a-sj:before {
  content: "\e650";
}

